<template>
  <div class="container-sm">
    <img
      class="img-fluid"
      src="../img/schueler.jpg"
      alt="Fünf Jugendliche welche Schulsachen in der Hand tragen und Lächeln"
    />
  </div>
  <div class="pt-4 container">
    <h2>Warum Kinder- und Jugendcoaching?</h2>
    <p>
      Coaching hat im beruflichen und sportlichen Umfeld einen festen Platz
      eingenommen. Es wird eingesetzt, um Schwierigkeiten zu erkennen, neue
      Perspektiven und innovative Lösungsansätze zu entwickeln und wirkt genauso
      bei Kindern wie Jugendlichen.<br />Herausforderungen in der Schule,
      schwelende Konflikte im täglichen Miteinander oder persönliche Probleme
      sind oft nicht nur für Kinder und Jugendliche eine große Belastung,
      sondern können auch Eltern und Lehrkräfte an ihre Grenzen bringen. Oft
      hilft die Perspektive von außen, bzw. einer neutralen Person, die
      Konflikte zu erfassen und zu entschärfen.<br />Das Coaching stärkt das
      Selbstbewusstsein Ihres Kindes, so dass es vertrauensvoll und motiviert in
      die Zukunft blicken kann. Ihr Kind lernt sich selbst wahrzunehmen. Es
      lernt seine eigenen Potentiale und Stärken kennen und bekommt Methoden an
      die Hand, jene weiterzuentwickeln.<br />Unser Coaching verhilft Ihrem Kind
      zu einer authentischen Persönlichkeit. Ein Coaching ersetzt aber keine
      psychologische Therapie oder Nachhilfe.
    </p>
    <div class="row pb-3">
      <div class="col flex-center">
        <h3>Vorteile für Ihr Kind</h3>
        <strong>Erst Lösen:</strong>
        <ul>
          <li>Ängste, Blockaden, Blackouts, Phobien</li>
        </ul>
        <strong>Dann Aufbauen:</strong>
        <ul>
          <li>leichter und effizienter lernen</li>
          <li>Herausforderungen mutig annehmen</li>
          <li>Stresssituationen selbständig bewältigen</li>
          <li>motiviert eigene Ziele verfolgen</li>
          <li>vertrauensvoll in die Zukunft schauen</li>
          <li>einfach wieder glücklich sein</li>
        </ul>
      </div>
      <div class="col flex-center">
        <img
          class="img"
          src="../img/studentin.jpg"
          alt="Lächelnde Kinder welche in einem Kreis herunter zur Kamera schauen"
        />
      </div>
    </div>
    <div class="row pb-3">
      <div class="col flex-center">
        <img
          class="img"
          src="../img/FamilieMitNeuling.jpg"
          alt="Glückliche Eltern mit zwei Kindern im Garten"
        />
      </div>
      <div class="col flex-center">
        <h3>Vorteile für Sie als Eltern</h3>
        <ul>
          <li>Die Stimmung innerhalb der Familie verbessert sich</li>
          <li>Das Thema Schule ist nicht mehr so bedrückend</li>
          <li>
            Wenn es Ihrem Kind gut geht, dann sind Sie als Eltern auch zufrieden
          </li>
        </ul>
      </div>
    </div>
    <div class="Banner">
      Wir bieten Ihnen gerne eine kostenlose telefonische Erstberatung.<br /><strong
        >Rufen Sie an unter: 0179 4682 530</strong
      >
    </div>
    <h3>Unsere Methoden</h3>
    <p>
      Wir, als zertifizierte Coaches, arbeiten auf Basis der aktuellen,
      wissenschaftlichen Erkenntnisse aus den Bereichen des Lern- und
      Konzentrationstrainings, der Gehirnstimulation und des Mentaltrainings. In
      dem Kurzzeitcoaching (6-8 Einzelsitzungen) bekommt Ihr Kind ein
      wirkungsvolles Methodenportfolio, um den heutigen Anforderungen des Lebens
      mutig zu begegnen.
    </p>
    <div class="accordion pb-3" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            iERT
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            Unser Schlaf hat mehrere Phasen. Eine davon ist die sogenannte
            REM-Phase (auch Traumphase genannt) in der die Informationen und
            Erlebnisse des Tages verarbeitet und zum Teil im Langzeitgedächtnis
            gespeichert werden. Manchmal aber können sehr starke emotionale
            Belastungen nicht verarbeitet werden und werden ungefiltert im
            limbischen System gespeichert. Man spricht dann von einer Blockade.
            Mit der IERT-Methode simulieren wir die REM-Phase nach und können so
            die Blockade lösen. Diese Methode wird sehr oft in der
            Traumatherapie benutzt.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            Klopfakupressur (wie EFT)
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            Unser Schlaf hat mehrere Phasen. Eine davon ist die sogenannte
            REM-Phase (auch Traumphase genannt) in der die Informationen und
            Erlebnisse des Tages verarbeitet und zum Teil im Langzeitgedächtnis
            gespeichert werden. Manchmal aber können sehr starke emotionale
            Belastungen nicht verarbeitet werden und werden ungefiltert im
            limbischen System gespeichert. Man spricht dann von einer Blockade.
            Mit der IERT-Methode simulieren wir die REM-Phase nach und können so
            die Blockade lösen. Diese Methode wird sehr oft in der
            Traumatherapie benutzt.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            Systematische Aufstellung
          </button>
        </h2>
        <div
          id="collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            Es ist viel einfacher aus der Vogelperspektive (wegen des guten
            Überblicks) aus einem Labyrinth heraus zu kommen. Mit Hilfe von
            Playmobil-Figuren können Kinder ihren internen Systemdialog
            anschauen und "von oben" betrachten. Interaktionen und
            Befindlichkeiten innerhalb eines Systems können veranschaulicht und
            Lösungen erarbeitet werden. Die Kinder stellen ein inneres Team aus
            Ratgebern auf, die sie in Ihrem Leben begleiten.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            Doppelter Future Back-Check
          </button>
        </h2>
        <div
          id="collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="headingFour"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            "Erst wenn du weißt, wohin du willst, weißt du auch, was du
            brauchst, um dorthin zu gelangen." (alter Weisheitspruch). Durch
            unterschiedliche Rollenspiele gehen wir mit den Kindern in
            verschiedene Vorstellungen von Zukunftssituationen, die parallel
            dann eine emotionale Referenz hinterlassen. Dabei werden die Ziele
            nicht nur erdacht und genannt, sondern auch erfühlt. Das macht Spaß,
            ist leicht und sehr wirkungsvoll.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFive"
            aria-expanded="false"
            aria-controls="collapseFive"
          >
            Alpha-Learning und Fantasiereise
          </button>
        </h2>
        <div
          id="collapseFive"
          class="accordion-collapse collapse"
          aria-labelledby="headingFive"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            Das Ziel unserer Fantasiereise ist das Kind in den Alpha-Zustand zu
            bringen, ein Zustand in dem die Frequenz der Gehirnwellen
            verlangsamt sind. In diesem Zustand, kann man am bestens
            Informationen aufnehmen, neuen Lernstoff lernen und speichern. Wir
            bringen dem Kind bei, sich selbständig in den Alpha-Zustand zu
            versetzen, damit es mehr Lernleistungen mit weniger Zeitaufwand
            erreichen kann.
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      <iframe
        class="center"
        src="https://www.youtube-nocookie.com/embed/wZixPRhdF_Y"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <h5>Hinweis:</h5>
    <p>
      Wir sind weder Ärztinnen noch Therapeutinnen. Die angebotenen
      Dienstleistungen sind keine Behandlung im medizinischen Sinn und dienen
      ausschließlich der Persönlichkeitsentwicklung und Gesundheitsförderung.
      Wir stellen keine Diagnosen und geben keine Heilungsversprechen ab. Wir
      verabreichen keine Medikamente und nehme keine Klienten an, die eine
      medizinische Behandlung brauchen.
    </p>
  </div>
</template>

<script>
export default {
  name: "TheCoaching",
};
</script>

<style scoped>
li {
  list-style: none;
}
iframe {
  width: 70%;
  aspect-ratio: 16 / 9;
}
</style>
