<template>
  <TheNav />
  <router-view></router-view>
  <TheFooter />
</template>

<script>
import TheNav from "./components/TheNav.vue";
import TheFooter from "./components/TheFooter.vue";

export default {
  name: "App",
  components: {
    TheNav,
    TheFooter,
  },
};
</script>

<style>
/*
-----CI & General-----
*/

* {
  font-family: "Nunito", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  color: #0e699e;
}
@font-face {
  font-family: "Noteworthy Light";
  font-style: normal;
  font-weight: normal;
  src: local("Noteworthy Light"), url("Noteworthy-Lt.woff") format("woff");
}
.bgc1 {
  background-color: #006b6e;
}
.btn {
  background-color: #80c900;
  color: #ffffff;
  border: 3px solid #80c900;
  font-weight: 800;
  padding: 10px 20px;
  text-decoration: none;
}
.btn:hover {
  background-color: #ffffff;
  color: #80c900;
  border: 3px solid #0e699e;
  animation: 0.5s;
}
h1,
h2,
h3,
h4,
h5 {
  color: #80c900;
  font-weight: 800;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.2rem;
}
h5 {
  font-size: 1rem;
}
strong {
  font-weight: 800;
}
small {
  font-size: 0.75em;
}
hr {
  color: #cccccc;
  opacity: 1;
  margin: 0.5rem auto;
  height: 2px !important;
}
.margin-center {
  margin: auto;
}
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sIcon {
  max-height: 64px;
  max-width: 64px;
}
.lIcon {
  height: 100px;
  width: 100px;
}
.img {
  max-height: 350px;
}
/* .bIcon {
  max-height: 350px;

  max-width: 350px;
} */
a {
  color: #0e699e;
}
a:hover {
  color: #80c900;
}
.Banner {
  height: 100%;
  width: 100%;
  background-color: #0e699e;
  color: #ffffff;
  padding: 1rem;
  margin-block: 1rem;
  border-radius: 0.25rem;
  text-align: center;
}
.Banner > strong {
  color: #ffffff;
}
.nounderline {
  text-decoration: none;
}

/*
-----Bootstrap changes-----
*/

.container {
  padding-inline: 2rem;
  padding-bottom: 1.5rem;
  background-color: #f5f5f5;
  min-height: calc(100vh - 4rem - 6rem);
}
.container-sm {
  padding: 0;
}
.bg-white {
  background-color: #0e699e !important;
}
.navbar-brand {
  font-family: Noteworthy Light;
  color: #80c900;
  padding: 0;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 0rem;
}
.nav-link {
  margin-inline: 0.5rem;
  font-weight: 800;
  color: #ffffff;
}
.nav-link:focus,
.nav-link:hover {
  color: #80c900;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0;
  border: 3px solid #80c900;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(128,201,0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-collapse {
  background-color: #0e699e;
}
.accordion-button {
  color: #0e699e;
  font-weight: 800;
}
.accordion-button:not(.collapsed) {
  color: #80c900;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  .nav-link {
    margin-inline: 0;
  }
  hr {
    width: 90%;
  }
  .sIcon {
    max-height: 32px;
    max-width: 32px;
  }
}
</style>
