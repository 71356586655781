import { createRouter, createWebHistory } from "vue-router";
import TheHomepage from "@/components/TheHomepage";
import TheCoaching from "@/components/TheCoaching";
import TheRIT from "@/components/TheRIT";
import TheUeberUns from "@/components/TheUeberUns";
import TheKontakt from "@/components/TheKontakt";
import TheImpressum from "@/components/TheImpressum";
import TheDatenschutz from "@/components/TheDatenschutz";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: TheHomepage,
    },
    {
      path: "/coaching",
      component: TheCoaching,
    },
    {
      path: "/rit",
      component: TheRIT,
    },
    {
      path: "/ueber-uns",
      component: TheUeberUns,
    },
    {
      path: "/kontakt",
      component: TheKontakt,
    },
    {
      path: "/impressum",
      component: TheImpressum,
    },
    {
      path: "/datenschutz",
      component: TheDatenschutz,
    },
  ],
});

export default router;
