<template>
  <div class="pt-4 container">
    <h1>Impressum</h1>
    <p>Angaben gemäß §5 TMG</p>
    <h3>Kinder & Jugendcoach Würmtal ist vertreten durch:</h3>
    <p>
      Florence Walther, Mobil: 0179 4682 530 E-Mail:
      F.Walther@jugendcoach-wuermtal.de
    </p>
    <p>
      Carola Bambas, Mobil: 0172 8111 903, E-Mail:
      C.Bambas@jugendcoach-wuermtal.de
    </p>
    <h3>Verantwortlich für den Inhalt:</h3>
    <p>Redaktion: Florence Walther</p>
    <p>Logo: Felix Walther & Linus Enzmann</p>
    <h3>Realisierung Webauftritt</h3>
    <p>Webseiten Design, Programmierung & Icon Erstellung</p>
    Felix Walther<br />Mobil: 015229247572<br />
    <a
      href="mailto:felix-raymond@waltheronline.com?=&subject=Kontakt Felix Walther&body="
      >felix-raymond@waltheronline.com</a
    ><br />
    <a href="https://felix-walther.com/" target="_blank"
      >www.felix-walther.com</a
    ><br /><br />
    <h2>Haftungsausschluss (Disclaimer)</h2>
    <h3>Haftung für Inhalte</h3>
    <p>
      Als Dienstanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf
      diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
      10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet, übermittelte
      oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
      zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
      nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
      konkreten Rechtsverletzung möglich. bei Bekanntwerden von entsprechenden
      Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </p>
    <h3>Haftung für Links</h3>
    <p>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter der Seiten verantwortlich. Die
      verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
      Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
      Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
      versinken Seiten ist jedoch ohne konkrete Anhaltspunkte einer
      Rechtsverletzung nicht zumutbar. Der Bekanntwerden von Rechtsverletzungen
      werden wir derartige Links umgehend entfernen.
    </p>
    <h3>Urheberrecht</h3>
    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nichtkommerziellen Gebrauch gestattet. Soweit die Inhalte auf
      dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
      Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
      gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
      aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
      entfernen.
    </p>
    <h3>Bildnachweise</h3>
    <p>
      Bildagentur.panthermedia.net<br />Pixabay (freie kommerzielle Nutzung)<br />iStock
    </p>
  </div>
</template>

<script>
export default {
  name: "TheImpressum",
};
</script>

<style scoped></style>
