<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="text-center">
          <h2 class="pt-4">Florence Walther</h2>
          <img
            class="img mt-3 mb-3"
            src="../img/Florence-Walther.jpg"
            alt="Bild von Florence Walther"
          />
        </div>
        <router-link to="/kontakt" class="flex-center nounderline pb-3"
          ><button class="btn">Zum Kontakt</button></router-link
        >
        <p>
          Wir sind beide ausgebildet und zertifiziert von der
          <a href="https://www.ipe-coaching.de/" target="_blank">IPE Akademie</a
          >, dem Institut für Potentialentfaltung. Unsere zweite Ausbildung zur
          Reflexintegrations-Trainerin haben wir beim
          <a href="https://rit-reflexintegration.de/" target="_blank"
            >Institut Sieber & Paasch</a
          >
          in München absolviert.
        </p>
        <h4>Ausbildung</h4>
        <ul>
          <li>
            Studium der Rechtswissenschaften in Metz, Straßburg und Heidelberg
          </li>
          <li>Didaktisch- methodische Fortbildung für Französischlehre</li>
          <li>Ausbildung zur Bewusstseinerweiterung und Heilarbeit</li>
          <li>Ausbildung zum Kinder- und Jugendcoach</li>
          <li>Ausbildung zur RIT- Reflexintegrations Trainerin</li>
        </ul>
        <h4>Lebensweg</h4>
        <p>
          Nach der Geburt meines vierten Kindes habe ich meinen juristischen
          Beruf aufgegeben und mich komplett neu orientiert. Seit 20 Jahren
          widme ich mich beruflich nur Kindern, als Lehrkraft für Französisch am
          Gymnasium in Gauting (5 Jahre), als Nachhilfelehrerin und als
          Pflegemutter für ein fünftes Kind.<br /><br />Nachdem ich festgestellt
          habe, dass sich immer mehr Kinder in der Schule schwer tun, ihre
          Lebensfreude verlieren, sich gestresst fühlen und antriebslos oder
          überfordert sind, bin ich mehr und mehr in die Rolle der
          Lebensberaterin gerutscht. Somit habe ich mich entschieden, die
          Ausbildung als Coach und RIT Trainerin zu machen, um die richtigen und
          passenden Techniken für Kinder zu erlernen.
        </p>
        <h4>Mein Ziel</h4>
        <p>
          ist es den Kindern und Jugendlichen zu helfen, ihren Weg zu finden und
          denen soviel Selbstvertrauen zu geben dass sie den Mut haben diesen
          Weg zu gehen. Es liegt mir sehr am Herzen, dass diese jungen Menschen
          mit großer Lebensfreude und Neugierde "Ja" zum Leben sagen.
        </p>
        <a
          href="https://walther.rit-reflexintegration.de/partner"
          target="_blank"
          >Offizielles RIT Profil 1</a
        >
        <br />
        <a
          href="https://rit-reflexintegration.de/modules/portals/fullentry.php?peid=46187&fid=72&location_id=274"
          target="_blank"
          >Offizielles RIT Profil 2</a
        >
      </div>
      <div class="col">
        <div class="text-center">
          <h2 class="pt-4">Carola Bambas</h2>
          <img
            class="img mt-3 mb-3"
            src="../img/Carola-Bambas.jpg"
            alt="Bild von Carola Bambas"
          />
        </div>
        <router-link to="/kontakt" class="flex-center nounderline pb-3"
          ><button class="btn">Zum Kontakt</button></router-link
        >
        <p>
          Wir sind beide ausgebildet und zertifiziert von der
          <a href="https://www.ipe-coaching.de/" target="_blank">IPE Akademie</a
          >, dem Institut für Potentialentfaltung. Unsere zweite Ausbildung zur
          Reflexintegrations-Trainerin haben wir beim
          <a href="https://rit-reflexintegration.de/" target="_blank"
            >Institut Sieber & Paasch</a
          >
          in München absolviert.
        </p>
        <h4>Ausbildung</h4>
        <ul>
          <li>Schauspielstudium in Berlin</li>
          <li>Theaterpädagogische Fortbildungen</li>
          <li>Ausbildung zur Gruppen- und Seminarleiterin</li>
          <li>
            Pädagogische Fachkraft für Bindungsentwicklung und Bindungsförderung
          </li>
          <li>Ausbildung zum Kinder- und Jugendcoach</li>
          <li>Ausbildung zur RIT- Reflexintegrations Trainerin</li>
        </ul>
        <h4>Lebensweg</h4>
        <p>
          Nach zehnjähriger Bühnentätigkeit und der Geburt meiner zwei Kinder,
          entdeckte ich den Eltern Kind Programm e.V. in Stockdorf. Deren
          pädagogischer Ansatz überzeugte mich sofort. Dort arbeite ich nun
          schon seit 15 Jahren mit großer Begeisterung als Gruppen- und
          Seminarleiterin. In meinen theaterpädagogischen Workshops zeige ich
          Kindern, wieviel Spaß es macht, sich zu trauen und sein kreatives
          Potential zu entfalten.<br />Mit den effektiven Methoden des Coachings
          und dem innovativen RIT Reflexintegrationstraining kann ich Kinder und
          Jugendliche nachhaltig darin unterstützen, Stolpersteine in der
          Persönlichkeitsentwicklung aus dem Weg zu räumen und einen eigenen,
          erfüllenden Lebensweg zu finden.
        </p>
        <h4>Mein Ziel</h4>
        <p>
          Glückliche, gelassene Kinder, die an sich und ihre Visionen für eine
          bessere Welt glauben, ist das, was die Menschheit braucht. Dafür
          brenne ich.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheUeberUns",
};
</script>

<style scoped></style>
