<template>
  <div class="container-sm">
    <img
      class="img-fluid"
      src="../img/glueckliche-kinder.jpg"
      alt="Sechs Kinder welche alle den Daumen nach oben zeigen und lächeln"
    />
  </div>
  <div class="container pt-4">
    <h2>Warum RIT - Reflexintegration?</h2>
    <p>
      Reflexintegrations Training ist ein hocheffizientes Unterstützungsprogramm
      für Kinder und Jugendliche mit:
    </p>
    <div class="row">
      <div class="col flex-center">
        <img class="img" src="../img/rit-problematiken.png" />
      </div>
      <div class="col margin-center">
        <h3>Schul- & Lernproblemen</h3>
        <ul>
          <li>Lese- und Rechtschreibschwäche</li>
          <li>Unruhe und Unkonzentriertheit</li>
          <li>Fehlende Impulskontrolle und Wutanfälle</li>
          <li>Häufige Bauch- und Kopfschmerzen</li>
        </ul>
        <h3>Motorische Problematiken</h3>
        <ul>
          <li>Schlechte Körperkoordination</li>
          <li>Ungeschicklichkeit</li>
          <li>Gleichgewichtsstörungen</li>
          <li>Unkoordinierte Fein- und Grobmotorik</li>
        </ul>
      </div>
    </div>
    <h3>Das ist oft ein Zeichen neuronaler Unreife</h3>
    <p>
      Spätestens nach der Einschulung fallen diese, für das Kind
      unkontrollierbaren, motorischen Verhaltensweisen auf und werden meist mit
      der Diagnose AD(H)S und /oder Legasthenie belegt.
    </p>
    <h3>Aber die gute Nachricht ist</h3>
    <p>
      Die Reifung des zentralen Nervensystems kann jedoch mithilfe des
      spezifischen, rhythmischen Bewegungstrainings der RIT - Reflexintegration
      nachgeholt werden.
    </p>
    <div class="row">
      <div class="col">
        <h2>Was ist ein Reflex?</h2>
        <p>
          Die frühkindlichen Reflexe sind wichtige genetisch festgelegte
          Bewegungsmuster. Sie sind während der Schwangerschaft, der Geburt und
          besonders im ersten Lebensjahr ein wesentlicher Bestandteil für die
          Entwicklung eines Kindes.<br />Wie ein interner persönlicher Trainer
          sorgen sie für die motorische Entwicklung und damit nach der Geburt
          für den Aufrichtungsprozess des Kindes. So ist es für uns Menschen
          möglich aufrecht zu stehen, zu gehen, den schweren Kopf gut
          ausbalanciert zu halten und in alle Richtungen frei bewegen zu
          können.<br />Im Laufe der ersten Lebensjahre werden diese
          automatischen Bewegungen "gehemmt" und das Kind kann sich mehr und
          mehr gezielt selbst bewegen, aufrecht stehen, den schweren Kopf tragen
          und in alle Richtungen bewegen.
        </p>
      </div>
      <div class="col-auto margin-center text-center">
        <img
          class="img"
          src="../img/Sauklaue.jpg"
          alt="krakelige Schrift eines Kindes"
        /><br />
        <strong>Typische Anzeichen</strong>
      </div>
    </div>
    <h2>Was passiert, wenn die Reflexe noch aktiv sind?</h2>
    <p>
      Die Gehirnentwicklung ist eng gekoppelt mit der motorischen Entwicklung.
      Nach der Geburt sind alle Gehirnareale vorhanden, müssen aber erst noch
      miteinander verknüpft werden. In den ersten Lebensjahren helfen die
      frühkindlichen Reflexe dabei. Danach treten sie in den Hintergrund.
      Bleiben jedoch frühkindliche Reflexbewegungen ganz oder teilweise aktiv,
      können sie jederzeit ausgelöst werden und verursachen unwillentliche
      Muskelreaktionen bei Kindern. Das Kind versucht diese Reflexe willentlich
      unter Kontrolle zu halten. Das passiert nur unter großer Anstrengung und
      kostet viel Energie. Das ist ein Zeichen neuronaler Unreife, also
      fehlender Nervenverbindungen im Gehirn. Dies führt zu den oben genannten
      Symptomen.<br />Neuronale Unreife bedeutet somit auch, dass die Potentiale
      der unterschiedlichen Gehirnareale noch nicht wirklich zur Verfügung
      stehen und damit einhergehende Lern- und Verhaltensprobleme die Folge
      sind.
    </p>
    <div class="row">
      <div class="col-auto margin-center text-center">
        <img
          class="img"
          src="../img/KindLernen.jpg"
          alt="Kind lachend am lernen"
        />
      </div>
      <div class="col margin-center">
        <h2>Die Lösung</h2>
        <p>
          RIT - Reflexintegration ist ein Bewegungsprogramm, wodurch die Reifung
          des zentralen Nervensystems nachgeholt werden kann. Die frühkindlichen
          Reflexe können sich zurückbilden, die kognitiven Bereiche des Gehirns
          sind somit frei zugänglich. Die oben genannten Symptome gehen deutlich
          zurück oder verschwinden ganz.
        </p>
      </div>
    </div>

    <h2>Ablauf des RIT - Trainings</h2>
    <p>
      Das RIT-Training dauert zwischen 6 und 12 Monate, wobei alle 4 Wochen eine
      Stunde beim RIT-Trainer stattfindet. In dieser Stunde werden Reflexe auf
      ihre Aktivität geprüft und mit einer Kombination aus erprobten
      Bewegungsabläufen und gleichzeitiger bilateraler Stimulation
      integriert.<br />Zwischen diesen Treffen sind zu Hause täglich einfache
      Übungen durchzuführen (ca. 15 Minuten).
    </p>
    <p class="thicc">
      Die Übungen zu Hause sind maßgeblich für den Erfolg des Trainings. Eine
      Unterstützung durch die Eltern ist unabdingbar wichtig!
    </p>
  </div>
</template>

<script>
export default {
  name: "TheRIT",
};
</script>

<style scoped>
.row {
  padding-bottom: 1.5rem;
}
</style>
