<template>
  <nav class="navbar navbar-expand-lg bg-white">
    <div class="container-md">
      <router-link to="/"
        ><img class="img-fluid" src="../img/Jugendcoach-Logo.svg" alt=""
      /></router-link>
      <router-link to="/" class="navbar-brand"
        >Kinder und<br />Jugendcoach Würmtal</router-link
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav margin-center text-center">
          <li class="nav-item">
            <router-link to="/" class="nav-link" href="#">Home</router-link>
          </li>
          <hr />
          <li class="nav-item">
            <router-link to="/coaching" class="nav-link" href="#"
              >Coaching</router-link
            >
          </li>
          <hr />
          <li class="nav-item">
            <router-link to="/rit" class="nav-link" href="#"
              >Reflexintegration</router-link
            >
          </li>
          <hr />
          <li class="nav-item">
            <router-link to="/ueber-uns" class="nav-link" href="#"
              >Über uns</router-link
            >
          </li>
        </ul>
      </div>
      <router-link class="verschwinde nounderline" to="/kontakt">
        <button class="btn d-flex">Kontakt</button>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TheNav",
};
</script>

<style scoped>
nav {
  height: 4rem;
}
img {
  height: 3rem;
}
@media screen and (max-width: 991px) {
  .verschwinde {
    display: none !important;
  }
}
</style>
