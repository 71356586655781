<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="text-center">
          <h2 class="pt-4">Florence Walther</h2>
          <img
            class="img mt-3 mb-3"
            src="../img/Florence-Walther.jpg"
            alt="Bild von Florence Walther"
          />
        </div>
        <div class="pb-3">
          <img class="sIcon me-3" src="../img/Haus-Icon.svg" alt="Haus Icon" />
          <a href="https://goo.gl/maps/DU66VNp1gwGsZaMQ9"
            >Luitpoldstraße 5, 82131 Gauting</a
          >
        </div>
        <div class="pb-3">
          <img
            class="sIcon me-3"
            src="../img/Mobil-Icon.svg"
            alt="Mobil Icon"
          />
          <a href="">0179 4682 530</a>
        </div>
        <div class="pb-3">
          <img class="sIcon me-3" src="../img/Mail-Icon.svg" alt="Mail Icon" />
          <a href="">F.Walther@jugendcoach-wuermtal.de</a>
        </div>
        <div class="pb-3">
          <img
            class="sIcon me-3"
            src="../img/Google-Icon.svg"
            alt="Google Icon"
          />
          <a href="https://g.page/r/CeibXb5lqA2OEAE">Zum Google Profil</a>
        </div>
        <div class="pb-3">
          <img class="sIcon me-3" src="../img/Xing-Icon.svg" alt="Xing Icon" />
          <a href="https://www.xing.com/profile/Florence_Walther/cv"
            >Zum Xing Profil</a
          >
        </div>
      </div>
      <div class="col">
        <div class="text-center">
          <h2 class="pt-4">Carola Bambas</h2>
          <img
            class="img mt-3 mb-3"
            src="../img/Carola-Bambas.jpg"
            alt="Bild von Carola Bambas"
          />
        </div>
        <div class="pb-3">
          <img class="sIcon me-3" src="../img/Haus-Icon.svg" alt="Haus Icon" />
          <a href="https://goo.gl/maps/iWLbhJfbUcbASzrUA"
            >Mühlthaler Weg 16, 82131 Gauting</a
          >
        </div>
        <div class="pb-3">
          <img
            class="sIcon me-3"
            src="../img/Mobil-Icon.svg"
            alt="Mobil Icon"
          />
          <a href="">0172 8111 903</a>
        </div>
        <div class="pb-3">
          <img class="sIcon me-3" src="../img/Mail-Icon.svg" alt="Mail Icon" />
          <a href="">C.Bambas@jugendcoach-wuermtal.de</a>
        </div>
        <div class="pb-3">
          <img
            class="sIcon me-3"
            src="../img/Google-Icon.svg"
            alt="Google Icon"
          />
          <a
            href="https://www.google.com/maps/place/Carola+Bambas+Kinder-und+Jugendcoach+W%C3%BCrmtal/@48.0426677,11.29677,13z/data=!4m9!1m2!2m1!1sKinder-+%26+Jugendcoach+W%C3%BCrmtal!3m5!1s0x479dd189c8fa5e0f:0xcf8ec84e9dec891a!8m2!3d48.0426649!4d11.3318072!15sCh5LaW5kZXItICYgSnVnZW5kY29hY2ggV8O8cm10YWySAQZzY2hvb2w"
            >Zum Google Profil</a
          >
        </div>
        <div class="pb-3">
          <img class="sIcon me-3" src="../img/Xing-Icon.svg" alt="Xing Icon" />
          <a href="https://www.xing.com/profile/Carola_Bambas/cv"
            >Zum Xing Profil</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheKontakt",
};
</script>

<style scoped></style>
