<template>
  <div class="pt-4 container">
    <h1>Datenschutz</h1>
    <p>
      Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden
      von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der
      Bereitstellung eines funktionsfähigen und nutzerfreundlichen
      Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
      Leistungen, verarbeitet.<br />Gemäß Art. 4 Ziffer 1. der Verordnung (EU)
      2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“
      genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
      automatisierter Verfahren ausgeführter Vorgang oder jede solche
      Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das
      Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die
      Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung,
      die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
      Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das
      Löschen oder die Vernichtung.<br />Mit der nachfolgenden
      Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang,
      Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten,
      soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und
      Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend
      über die von uns zu Optimierungszwecken sowie zur Steigerung der
      Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte
      Daten in wiederum eigener Verantwortung verarbeiten.<br />Unsere
      Datenschutzerklärung ist wie folgt gegliedert:<br /><br />I. Informationen
      über uns als Verantwortliche<br />II. Rechte der Nutzer und Betroffenen<br />III.
      Informationen zur Datenverarbeitung
    </p>
    <h3>I. Informationen über uns als Verantwortliche</h3>
    <p>
      Verantwortlicher Anbieter dieses Internetauftritts im
      datenschutzrechtlichen Sinne ist:
    </p>
    <p>
      Florence Walther<br />Luitpoldstr. 5<br />82131 Gauting<br />Deutschland<br />Telefon:
      0179 4682530<br />E-Mail: F.Walther@jugendcoach-wuermtal.de
    </p>
    <p>&</p>
    <p>
      Carola Bambas <br />Mühlthaler Weg 16<br />82131 Gauting<br />Deutschland<br />Telefon:
      0172 8111903<br />E-Mail: C.Bambas@jugendcoach-wuermtal.de
    </p>
    <h3>II. Rechte der Nutzer und Betroffenen</h3>
    <p>
      Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung
      haben die Nutzer und Betroffenen das Recht
    </p>
    <ul>
      <li>
        auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf
        Auskunft über die verarbeiteten Daten, auf weitere Informationen über
        die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15
        DSGVO);
      </li>
      <li>
        auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger
        Daten (vgl. auch Art. 16 DSGVO);
      </li>
      <li>
        auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17
        DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17
        Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach
        Maßgabe von Art. 18 DSGVO;
      </li>
      <li>
        auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und
        auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl.
        auch Art. 20 DSGVO);
      </li>
      <li>
        auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht
        sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß
        gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch
        Art. 77 DSGVO).
      </li>
    </ul>
    <p>
      Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen
      gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede
      Berichtigung oder Löschung von Daten oder die Einschränkung der
      Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu
      unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese
      Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden
      ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese
      Empfänger.<br />Ebenfalls haben die Nutzer und Betroffenen nach Art. 21
      DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie
      betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von
      Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein
      Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung
      statthaft.
    </p>
    <h3>III. Informationen zur Datenverarbeitung</h3>
    <p>
      Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden
      gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der
      Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
      entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen
      Verarbeitungsverfahren gemacht werden.
    </p>
    <h3>Serverdaten</h3>
    <p>
      Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren
      und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser
      an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog.
      Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das
      Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt
      gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts,
      die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die
      IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres
      Internetauftritts erfolgt, erhoben.<br />Diese so erhobenen Daten werden
      vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten
      von Ihnen.<br />Diese Speicherung erfolgt auf der Rechtsgrundlage von Art.
      6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
      Verbesserung, Stabilität, Funktionalität und Sicherheit unseres
      Internetauftritts.<br />Die Daten werden spätestens nach sieben Tage
      wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken
      erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung
      eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.
    </p>
    <h3>Cookies</h3>
    <p>
      a) Sitzungs-Cookies/Session-Cookies Wir verwenden mit unserem
      Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere
      Speichertechnologien, die durch den von Ihnen eingesetzten
      Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch
      diese Cookies werden im individuellen Umfang bestimmte Informationen von
      Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre
      IP-Adresse, verarbeitet.<br />Durch diese Verarbeitung wird unser
      Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die
      Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in
      unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion
      ermöglicht.<br />Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit
      b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder
      Vertragsabwicklung verarbeitet werden.<br />Falls die Verarbeitung nicht
      der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser
      berechtigtes Interesse in der Verbesserung der Funktionalität unseres
      Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f)
      DSGVO.<br />Mit Schließen Ihres Internet-Browsers werden diese
      Session-Cookies gelöscht.<br />b) Drittanbieter-Cookies<br />Gegebenenfalls
      werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen,
      mit denen wir zum Zwecke der Werbung, der Analyse oder der
      Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.<br />Die
      Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen
      der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den
      nachfolgenden Informationen.<br />c) Beseitigungsmöglichkeit<br />Sie
      können die Installation der Cookies durch eine Einstellung Ihres
      Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie
      bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen
      Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten
      Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion
      oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen
      Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung
      allerdings nicht über die Einstellungen des Browsers unterbunden werden.
      Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players
      ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von
      Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher
      bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder
      wenden sich an den Hersteller bzw. Benutzer-Support.<br />Sollten Sie die
      Installation der Cookies verhindern oder einschränken, kann dies
      allerdings dazu führen, dass nicht sämtliche Funktionen unseres
      Internetauftritts vollumfänglich nutzbar sind.
    </p>
    <h3>Newsletter</h3>
    <p>
      Falls Sie sich für unseren kostenlosen Newsletter anmelden, werden die von
      Ihnen hierzu abgefragten Daten, also Ihre E-Mail-Adresse sowie - optional
      - Ihr Name und Ihre Anschrift, an uns übermittelt. Gleichzeitig speichern
      wir die IP-Adresse des Internetanschlusses von dem aus Sie auf unseren
      Internetauftritt zugreifen sowie Datum und Uhrzeit Ihrer Anmeldung. Im
      Rahmen des weiteren Anmeldevorgangs werden wir Ihre Einwilligung in die
      Übersendung des Newsletters einholen, den Inhalt konkret beschreiben und
      auf diese Datenschutzerklärung verwiesen. Die dabei erhobenen Daten
      verwenden wir ausschließlich für den Newsletter-Versand – sie werden
      deshalb insbesondere auch nicht an Dritte weitergegeben.<br />Rechtsgrundlage
      hierbei ist Art. 6 Abs. 1 lit. a) DSGVO.<br />Die Einwilligung in den
      Newsletter-Versand können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit
      Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über
      Ihren Widerruf in Kenntnis setzen oder den in jedem Newsletter enthaltenen
      Abmeldelink betätigen.
    </p>
    <h3>Kontaktanfragen / Kontaktmöglichkeiten</h3>
    <p>
      Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten,
      werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage
      genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre
      Anfrage erforderlich - ohne deren Bereitstellung können wir Ihre Anfrage
      nicht oder allenfalls eingeschränkt beantworten.<br />Rechtsgrundlage für
      diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.<br />Ihre Daten werden
      gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der
      Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie
      bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.
    </p>
    <h3>Youtube</h3>
    <p>
      In unserem Internetauftritt setzen wir YouTube ein. Hierbei handelt es
      sich um ein Videoportal der YouTube LLC., 901 Cherry Ave., 94066 San
      Bruno, CA, USA, nachfolgend nur „YouTube“ genannt.<br />YouTube ist ein
      Tochterunternehmen der Google LLC., Gordon House, Barrow Street, Dublin 4,
      Irland, nachfolgend nur „Google“ genannt.<br />Durch die Zertifizierung
      nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)<a
        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
        target="_blank"
      >
        hier zu finden </a
      >garantiert Google und damit auch das Tochterunternehmen YouTube, dass die
      Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA
      eingehalten werden.<br />Wir nutzen YouTube im Zusammenhang mit der
      Funktion „Erweiterter Datenschutzmodus“, um Ihnen Videos anzeigen zu
      können. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
      berechtigtes Interesse liegt in der Qualitätsverbesserung unseres
      Internetauftritts. Die Funktion „Erweiterter Datenschutzmodus“ bewirkt
      laut Angaben von YouTube, dass die nachfolgend noch näher bezeichneten
      Daten nur dann an den Server von YouTube übermittelt werden, wenn Sie ein
      Video auch tatsächlich starten.<br />Ohne diesen „Erweiterten Datenschutz“
      wird eine Verbindung zum Server von YouTube in den USA hergestellt, sobald
      Sie eine unserer Internetseiten, auf der ein YouTube-Video eingebettet
      ist, aufrufen.<br />Diese Verbindung ist erforderlich, um das jeweilige
      Video auf unserer Internetseite über Ihren Internet-Browser darstellen zu
      können. Im Zuge dessen wird YouTube zumindest Ihre IP-Adresse, das Datum
      nebst Uhrzeit sowie die von Ihnen besuchte Internetseite erfassen und
      verarbeiten. Zudem wird eine Verbindung zu dem Werbenetzwerk „DoubleClick“
      von Google hergestellt.<br />
      Sollten Sie gleichzeitig bei YouTube eingeloggt sein, weist YouTube die
      Verbindungsinformationen Ihrem YouTube-Konto zu. Wenn Sie das verhindern
      möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts
      bei YouTube ausloggen oder die entsprechenden Einstellungen in Ihrem
      YouTube-Benutzerkonto vornehmen.<br />Zum Zwecke der Funktionalität sowie
      zur Analyse des Nutzungsverhaltens speichert YouTube dauerhaft Cookies
      über Ihren Internet-Browser auf Ihrem Endgerät. Falls Sie mit dieser
      Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die
      Speicherung der Cookies durch eine Einstellung in Ihrem Internet-Browsers
      zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter
      „Cookies“.<br />Weitergehende Informationen über die Erhebung und Nutzung
      von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält
      Google<a href="https://policies.google.com/privacy" target="_blank">
        hier </a
      >abrufbaren Datenschutzhinweisen bereit.
    </p>
    <h3>Vimeo</h3>
    <p>
      Zur Darstellung von Videos setzen wir auf unserer Internetseite „Vimeo“
      ein. Hierbei handelt es sich um einen Dienst der Vimeo, LL C, 555 West 18
      th Street, New York, New York 10011, USA, nachfolgend nur „Vimeo“ genannt,
      ein.<br />Teilweise erfolgt die Verarbeitung der Nutzerdaten auf Servern
      von Vimeo in den USA. Durch die Zertifizierung nach dem
      EU-US-Datenschutzschild („EU-US Privacy Shield“)<a
        href="https://www.privacyshield.gov/participant?id=a2zt00000008V77AAE&status=Active"
        target="_blank"
      >
        hier zu finden </a
      >garantiert Vimeo aber, dass die Datenschutzvorgaben der EU auch bei der
      Verarbeitung von Daten in den USA eingehalten werden.<br />Rechtsgrundlage
      ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
      Qualitätsverbesserung unseres Internetauftritts.<br />Besuchen Sie eine
      Seite unseres Internetauftritts, in die ein Video eingebettet ist, wird
      zur Darstellung des Videos eine Verbindung zu den Servern von Vimeo in den
      USA hergestellt. Aus technischen Gründen ist es dabei notwendig, dass
      Vimeo Ihre IP - Adresse verarbeitet. Daneben werden aber auch Datum und
      Uhrzeit des Besuchs unserer Internetseiten erfasst.<br />Sollten Sie
      gleichzeitig bei Vimeo eingeloggt sein, während Sie eine unserer
      Internetseiten besuchen, in die ein Vimeo-Video eingebettet ist, weist
      Vimeo die so gesammelten Informationen womöglich Ihrem dortigen
      persönlichen Nutzerkonto zu. Wenn Sie das verhindern möchten, müssen Sie
      sich entweder vor dem Besuch unseres Internetauftritts bei Vimeo ausloggen
      oder Ihr Nutzerkonto bei Vimeo entsprechend konfigurieren.<br />Zum Zwecke
      der Funktionalität und Nutzungsanalyse setzt Vimeo den Webanalysedienst
      Google Analytics ein. Durch Google Analytics werden Cookies über Ihren
      Internet-Browser auf Ihrem Endgerät gespeichert und Informationen über die
      Benutzung unserer Internetseiten, in die ein Vimeo- Video eingebettet ist,
      an Google übersandt. Hierbei kann nicht ausgeschlossen werden, dass Google
      diese Informationen in den USA verarbeitet.<br />Sofern Sie mit dieser
      Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die
      Installation der Cookies durch die entsprechenden Einstellungen in Ihrem
      Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend
      unter dem Punkt „Cookies“.<br />Rechtsgrundlage ist Art. 6 Abs. 1 lit. f)
      DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung
      unseres Internetauftritts und in dem berechtigten Interesse von Vimeo ,
      das Nutzerverhalten statistisch zu Optimierungs- und Marketingzwecken zu
      analysieren.<br />Vimeo bietet
      <a href="http://vimeo.com/privacy" target="_blank"> hier </a> weitere
      Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren Rechten
      und Möglichkeiten zum Schutz Ihrer Privatsphäre an.
    </p>
    <p>
      <a
        href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
        target="_blank"
        >Muster-Datenschutzerklärung</a
      >
      der
      <a href="https://www.ratgeberrecht.eu/" target="_blank"
        >Anwaltskanzlei Weiß & Partner</a
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
