<template>
  <nav class="pt-3 pb-3 navbar navbar-expand-sm bg-white">
    <div class="container-md">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/kontakt" class="nav-link" href="#"
            >Kontakt</router-link
          >
        </li>
        <hr />
        <li class="nav-item">
          <router-link to="/impressum" class="nav-link" href="#"
            >Impressum</router-link
          >
        </li>
        <hr />
        <li class="nav-item">
          <router-link to="/datenschutz" class="nav-link" href="#"
            >Datenschutz</router-link
          >
        </li>
      </ul>
      <img class="d-flex" src="../img/Jugendcoach-Logo.svg" alt="" />
    </div>
  </nav>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped>
nav {
  height: 6rem;
}
img {
  height: 5rem;
}
@media screen and (max-width: 575px) {
  nav {
    height: 12rem;
  }
}
</style>
