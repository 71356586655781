<template>
  <div class="container-sm">
    <img
      class="img-fluid"
      src="../img/happy-kid.jpg"
      alt="Ein lebensfrohes Kind auf einer Wiese"
    />
  </div>
  <div class="container text-center pt-4">
    <h1>Glückliche Kinder und Jugendliche</h1>
    <strong>
      das ist unser Ziel und unsere Herzensangelegenheit. Mit viel Empathie und
      Freude unterstützen wir junge Menschen, die Herausforderungen und
      Anforderungen des Lebens leichter zu meistern.
    </strong>
    <div class="Banner">
      Wir bieten Ihnen gerne eine kostenlose telefonische Erstberatung.<br /><strong
        >Rufen Sie an unter: 0179 4682 530</strong
      >
    </div>
    <div class="row justify-content-center">
      <div class="col flex-center">
        <h1 class="mt-3">Coaching</h1>
        <strong>Sinnvoll bei</strong>
        <ul class="mt-3">
          <li>(Lern-) Blockaden</li>
          <li>Mobbing</li>
          <li>(Schul-) Ängsten</li>
          <li>Mangelndem Selbstwertgefühl</li>
          <li>Aggressionen</li>
          <li>Konzentrationsschwierigkeiten</li>
          <li>Antriebslosigkeit</li>
        </ul>
        <div class="IconRow">
          <img
            class="lIcon"
            src="../img/IPE-Zertifiziert1.svg"
            alt="Zertifizierung als IPE Kinder- & Jugendcoach"
          />
          <img
            class="lIcon"
            src="../img/IPE-Zertifiziert2.svg"
            alt="Zertifizierung als IPE Lerncoach"
          />
        </div>
        <router-link to="/coaching">
          <button class="btn mt-3">Mehr Erfahren</button>
        </router-link>
      </div>
      <div class="col flex-center">
        <h1 class="mt-3">Reflexintegration</h1>
        <strong>Sinnvoll bei</strong>
        <ul class="mt-3">
          <li>Lese- und Rechtschreibschwäche</li>
          <li>Unruhe und Unkonzentriertheit</li>
          <li>Fehlender Impulskontrolle</li>
          <li>Problemen mit Fein-und Grobmotorik</li>
          <li>Schlechter Körperkoordination</li>
          <li>Gleichgewichtsstörungen</li>
          <li>Verkrampfter Stifthaltung</li>
        </ul>
        <div class="IconRow">
          <img
            class="lIcon"
            src="../img/RIT-Zertifiziert.svg"
            alt="Zertifizierung als RIT Coach"
          />
          <img
            class="lIcon"
            src="../img/Von-Ärzten-Empfohlen.svg"
            alt="Von Ärzten empfohlen"
          />
        </div>
        <router-link to="/rit">
          <button class="btn mt-3">Mehr Erfahren</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheHomepage",
};
</script>

<style scoped>
ul {
  text-align: left;
}
.container {
  min-height: calc(80vh - 4rem - 6rem);
}
</style>
